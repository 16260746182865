import axios from "axios";
import router from "@/router";
import vuex from "@/store";
const local = {
  createAxiosInstance() {
    local.apiInstance = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
    });
  },
};
const authenticate = {
  login(documento, password) {
    return new Promise((resolve, reject) => {
      if (!documento || !password) {
        reject(new Error("Empty Username/Password not allowed"));
        return;
      }

      local.apiInstance
        .post("/login", {
          documento,
          password,
        })
        .then((response) => {
          console.log(response.data);
          // Commit state authenticated vuex
          vuex.commit("token", response.data.token);
          vuex.commit("user", response.data.user);
          // router push to home
          router.push("/");
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
// self invoking function
(() => {
  local.createAxiosInstance();
})();
export default authenticate;
