<template>
  <div class="contenedor fondo">
    <div
      class="mb-n15"
      style="
        height: 150px;
        overflow: hidden;
        margin-top: auto;
        margin-bottom: auto;
      "
    >
      <svg
        viewBox="0 0 500 139"
        preserveAspectRatio="none"
        style="height: 100%; width: 100%"
      >
        <path
          d="M0.00,49.98 C149.99,150.00 271.49,-49.98 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
          style="stroke: none; fill: #5c6bc0"
        ></path>
      </svg>
    </div>

    <v-row justify="center" align="center" class="mt-sm-16">
      <v-col cols="12" sm="5">
        <v-row justify="center" align="center">
          <v-img class="imagen ml-sm-16" contain src="@/assets/login.png">
          </v-img>
        </v-row>
      </v-col>
      <v-col cols="12" sm="7">
        <v-row class="mx-10 mx-md-16 mt-2" align="center" justify="center">
          <h2 class="text-center" style="color: 01579B">Iniciar Sesión</h2>
          <v-col cols="12">
            <label class="ml-5" for="">Documento</label>
            <v-text-field v-model="documento" outlined dense shaped>
              <v-icon slot="prepend-inner" color="primary"> mdi-account</v-icon>
            </v-text-field>
          </v-col>
          <v-col cols="12" class="mt-n7">
            <label class="ml-5" for="">Contraseña</label>
            <v-text-field
              v-model="contrasena"
              type="password"
              outlined
              dense
              shaped
            >
              <v-icon slot="prepend-inner" color="primary"> mdi-key </v-icon>
            </v-text-field>
          </v-col>
          <v-col cols="12" align="center" class="mt-n6">
            <v-btn @click="login" color="primary">Ingresar</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <svg
      style="position: absolute; bottom: 0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 270"
    >
      <path
        fill="#5C6BC0"
        fill-opacity="1"
        d="M0,0L26.7,48C53.3,96,107,192,160,213.3C213.3,235,267,181,320,176C373.3,171,427,213,480,213.3C533.3,213,587,171,640,149.3C693.3,128,747,128,800,149.3C853.3,171,907,213,960,197.3C1013.3,181,1067,107,1120,112C1173.3,117,1227,203,1280,229.3C1333.3,256,1387,224,1413,208L1440,192L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"
      ></path>
    </svg>
  </div>
</template>

<script>
import authenticate from "@/modules/authenticate";
export default {
  data: () => ({
    documento: null,
    contrasena: null,
  }),
  beforeCreate() {
    if (this.$store.state.token != "") {
      this.$router.push({
        name: "Dashboard",
      });
    }
  },
  created() {
    console.log(this.$store);
  },
  methods: {
    async login() {
      authenticate
        .login(this.documento, this.contrasena)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          // Add error message
        });
    },
  },
};
</script>

<style scoped>
.contenedor {
  min-width: 100vw !important;
  min-height: 100vh !important;
}

.enlace {
  color: #01579b;
  text-align: center;
  cursor: pointer !important;
}

.imagen {
  width: 100% !important;
  max-width: 350px !important;
}

@media only screen and (min-width: 220px) and (max-width: 596px) {
  .imagen {
    width: 100% !important;
    max-width: 180px !important;
  }
}

.bienvenido {
  color: rgb(163, 76, 245);
  font-size: 32px;
  font-family: "Poppins", sans-serif;
}

h2 {
  color: rgb(95, 154, 243);
}

.v-text-field--outlined >>> fieldset {
  border-color: rgba(92, 75, 252, 0.986);
}

.fondo {
  background-color: #fff;
}
</style>
